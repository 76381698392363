import { haveGtmAndGaLoaded } from '../AnalyticsHelpers'

export function getImpressions () {
  if (!haveGtmAndGaLoaded()) return []
  return window.google_tag_manager[process.env.GOOGLE_TAG_MANAGER_ID].dataLayer.get('ecommerce.impressions') || []
}

// Data to be used by GTM True View Impression handler
// get new listing impressions, concat with what's already on the data layer currently
// and push everything back to data layer
export function registerNewImpressions (newImpressions) {
  const currentImpressions = getImpressions()
  const allImpressions = currentImpressions.concat(newImpressions)

  if (!haveGtmAndGaLoaded()) return

  window.dataLayer.push({
    ecommerce: {
      impressions: allImpressions
    }
  })
}

export function getLastImpression () {
  const impressions = getImpressions()
  return impressions[impressions.length - 1]
}

/* eslint-disable */
export function simplifyImpression ({ listing_id, listing_position, search_query_id }) {
  return { listing_id, listing_position, search_query_id }
}
/* eslint-enable */
