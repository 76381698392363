export default function closeNotificationsOnClick () {
  const notificationCloseButtons = Array.from(document.getElementsByClassName('vjs-notification-close'))

  notificationCloseButtons.forEach(closeButton => {
    closeButton.addEventListener('click', e => {
      const notificationContainer = e.currentTarget.closest('.vjs-notification-container')
      if (notificationContainer) notificationContainer.classList.add('hidden')
    })
  })
}
