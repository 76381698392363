/* global OMETRIA_ID */
import { haveGtmAndGaLoaded } from '../../analytics/AnalyticsHelpers'
import pipe from 'ramda/src/pipe'

const dataLayer = () => window.dataLayer[0]

function getDataLayerBasket (dataLayer) {
  return dataLayer.basket
}

function getDataLayerCurrency () {
  return dataLayer().page.currency
}

function setOmetriaBasket (basket) {
  window.ometria.setBasket(basket)
  return window.ometria.getBasket()
}

function setOmetriaBasketVars (dataLayerBasket) {
  let ometriaBasket = initOmetriaBasket()
  ometriaBasket.setId(dataLayerBasket.id)
  ometriaBasket.setUrl(vinteriorBasketUrl())
  ometriaBasket.setTotal(dataLayerBasket.total, getDataLayerCurrency())
  const basketItems = dataLayerBasket.active_items
  ometriaBasket = setbasketItems(basketItems, ometriaBasket)
  return ometriaBasket
}

function initOmetriaBasket () {
  return new window.ometria.Basket()
}

function vinteriorBasketUrl () {
  return window.location.origin + '/basket'
}

function setbasketItems (basketItems, ometriaBasket) {
  basketItems.forEach((item) => {
    ometriaBasket.addLineItem(item.listing_id, item.quantity, item.subtotal)
  })
  return ometriaBasket
}

function initPage (pageName, pageData) {
  window.ometria.init(pageName, pageData, 'vinterior.co/uk')
}

function initProductPage () {
  const pid = dataLayer().listing.id
  initPage('product', { pid: pid })
}

function chooseAndInitPage (page) {
  switch (page) {
    case 'homepage':
      initPage('homepage', null)
      break
    case 'new page':
    case 'sale page':
    case 'search page':
      // For Ometria a listing page is a collection of listings/products
      // e.g. search or collection page for Vinterior
      initPage('listing', null)
      break
    case 'basket page':
      initPage('basket', null)
      break
    case 'checkout':
      initPage('checkout', null)
      break
    case 'confirmation':
      initPage('confirmation', null)
      break
    case 'listing page':
      initProductPage()
      break
  }
}

function identifyUser () {
  const userExists = !!dataLayer().user && dataLayer().user.is_logged_in
  if (userExists) {
    const user = dataLayer().user
    window.ometria.identify(user.email)
  }
}

function buildBasket () {
  pipe(
    getDataLayerBasket,
    setOmetriaBasketVars,
    setOmetriaBasket
  )(dataLayer())
}

function trackTransaction (pageName) {
  if (pageName === 'confirmation') {
    const orderId = dataLayer().order.id
    window.ometria.trackTransaction(orderId)
  }
}

export function ometriaAddToCart (listingId) {
  if (!window.ometria) return

  const quantity = 1
  window.ometria.trackAddToBasket(listingId, quantity)
}

function loadScript (url, callback) {
  const head = Array.from(document.getElementsByTagName('head'))[0]
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url
  script.async = true
  script.onload = callback

  head.appendChild(script)
}

export default function () {
  const url = `//cdn.ometria.com/tags/${OMETRIA_ID}.js`

  loadScript(url, () => {
    if (!haveGtmAndGaLoaded() || !window.ometria) return

    const pageName = dataLayer().page.funnel_step
    chooseAndInitPage(pageName)
    identifyUser()
    buildBasket()
    trackTransaction(pageName)
  })
}
