import { pushToDataLayer } from '../PushToDataLayer'

export function trackSaveSearchClick (element) {
  const [category, subcategory, productType, subProductType] = getHierarchy()
  const { storefront, sellerId } = element.dataset

  pushToDataLayer('saved search click (Vinterior)', {
    category: category || null,
    subcategory: subcategory || null,
    productType: productType || null,
    subProductType: subProductType || null,
    storefront: storefront === 'true',
    seller_id: sellerId || null
  })
}
export function trackSaveSearch (element) {
  const [category, subcategory, productType, subProductType] = getHierarchy()
  const { storefront, sellerId } = element.dataset

  pushToDataLayer('saved search (Vinterior)', {
    category: category || null,
    subcategory: subcategory || null,
    productType: productType || null,
    subProductType: subProductType || null,
    storefront: storefront === 'true',
    seller_id: sellerId || null
  })
}

function getHierarchy () {
  const searchResults = window.google_tag_manager[process.env.GOOGLE_TAG_MANAGER_ID]?.dataLayer.get('search_results') || {}
  const hierarchyFilters = searchResults?.applied_filters?.find(isHierarchyFilter)

  return hierarchyFilters?.filter_value?.split(' / ') || []
}

function isHierarchyFilter (filter) {
  return filter.filter_name === 'category_hierarchy.lvl0'
}
