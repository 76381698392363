import { onCLS, onFID, onLCP, onFCP, onTTFB, onINP } from 'web-vitals'
import { haveGtmAndGaLoaded } from './AnalyticsHelpers'
import { pushToDataLayer } from './PushToDataLayer'

function sendWebCoreVitalsToGTM ({ name, delta, id }) {
  if (haveGtmAndGaLoaded()) {
    const customEventProperties = {
      webVitals: {
        event_category: 'Web Vitals',
        event_action: name,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        event_value: Math.round(name === 'CLS' ? delta * 1000 : delta),
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID (note: requires `eventLabel` to
        // be a dimension in your report).
        event_label: id
      }
    }
    pushToDataLayer('web-vitals', customEventProperties)
  }
}

export function getWebCoreVitals () {
  onCLS(sendWebCoreVitalsToGTM)
  onFID(sendWebCoreVitalsToGTM)
  onLCP(sendWebCoreVitalsToGTM)
  onFCP(sendWebCoreVitalsToGTM)
  onTTFB(sendWebCoreVitalsToGTM)
  onINP(sendWebCoreVitalsToGTM)
}
