export default function setDispatchForm () {
  const shipmentType = document.getElementById('vjs-shipment-type-select')
  const courierNameSelects = document.querySelectorAll('.vjs-courier-name-select')

  if (!shipmentType || !courierNameSelects) return

  shipmentType.addEventListener('change', (e) => showCourierNameSelector(e))
  courierNameSelects.forEach(courierNameSelect =>
    courierNameSelect.addEventListener('change', (e) => showInputForOther(e))
  )
}

function showCourierNameSelector (e) {
  const parcelSelectSection = document.getElementById('vjs-parcel-select-section')
  const courierSelectSection = document.getElementById('vjs-courier-select-section')
  const courierInputSection = document.getElementById('vjs-courier-input-section')

  const sections = [parcelSelectSection, courierSelectSection, courierInputSection]

  const parcelSelect = document.getElementById('vjs-parcel-select')
  const courierSelect = document.getElementById('vjs-courier-select')
  const courierInput = document.getElementById('vjs-courier-input')

  const inputs = [parcelSelect, courierSelect, courierInput]

  const shipmentType = e.target.value

  if (shipmentType === 'parcel') {
    resetInputs(inputs)
    resetSections(sections)
    showSection(parcelSelectSection)
    useInputValueInForm(parcelSelect)
  } else if (['courier - crated', 'courier - classic'].includes(shipmentType)) {
    resetInputs(inputs)
    resetSections(sections)
    showSection(courierSelectSection)
    useInputValueInForm(courierSelect)
  } else if (shipmentType === 'other') {
    resetInputs(inputs)
    resetSections(sections)
    showSection(courierInputSection)
    useInputValueInForm(courierInput)
  } else {
    resetInputs(inputs)
    resetSections(sections)
  }
}

function useInputValueInForm (input) {
  input.name = 'line_item[courier_name]'
  input.required = true
}

function showSection (section) {
  section.classList.remove('hidden')
}

function resetInputs (inputs) {
  inputs.forEach(input => {
    input.name = ''
    input.required = false
  })
}

function resetSections (sections) {
  sections.forEach(section => { section.classList.add('hidden') })
}

function showInputForOther (e) {
  const courierName = e.target.value

  const courierInputSection = document.getElementById('vjs-courier-input-section')
  const courierInput = document.getElementById('vjs-courier-input')

  if (['other', 'independent_contractor'].includes(courierName)) {
    showSection(courierInputSection)
    useInputValueInForm(courierInput)
  }
}
