import { slideToggle } from '../../utils/animationHelpers'

function attachClickHandlerToBurgerMenuButton () {
  const burgerBtn = document.getElementById('toggle-button')
  const burgerMenu = document.getElementById('mobile-menu')
  if (!burgerBtn || !burgerMenu) return

  burgerBtn.addEventListener('click', function () {
    window.scrollTo(0, 0)

    if (burgerMenu.classList.contains('in')) {
      burgerMenu.classList.remove('in')
      document.body.classList.remove('noscroll')
    } else {
      burgerMenu.classList.add('in')

      if (!burgerMenu.classList.contains('mobile-admin')) {
        document.body.classList.add('noscroll')
      }
    }
  })
}

function slideToggleMobileNavItems () {
  // Display mobile new-design menu lists on click //
  const triggersMobileMenu = document.getElementsByClassName('mobile-menu__tab')
  Array.from(triggersMobileMenu).forEach(function (element) {
    const targetMobileMenuElement = document.querySelector(element.dataset.targetElement)
    element.addEventListener('click', function () {
      if (targetMobileMenuElement) {
        targetMobileMenuElement.classList.toggle('active')
        if (targetMobileMenuElement.classList.contains('active') && !element.childNodes[1].classList.contains('mobile-filter-chevron')) {
          element.childNodes[1].setAttribute('data-icon', 'k')
        } else if (!element.childNodes[1].classList.contains('mobile-filter-chevron')) {
          element.childNodes[1].setAttribute('data-icon', 'n')
        }
      }
    })
  })

  // Display mobile new-design deeper menu lists on click //
  const lastDepthLevelMenus = document.getElementsByClassName('last_depth_level')
  Array.from(lastDepthLevelMenus).forEach(function (element) {
    const lastDepthLevelMenu = document.querySelector(element.dataset.targetElement)
    element.addEventListener('click', function () {
      slideToggle(lastDepthLevelMenu)
    })
  })

  // Remove mobile new-design menu lists on click //
  const mobileMenuBackLinks = Array.from(document.getElementsByClassName('mobile-menu__back-link'))

  mobileMenuBackLinks.forEach(element => {
    element.addEventListener('click', function () {
      const menuToClose = element.parentNode.parentNode

      if (menuToClose.classList.contains('active')) {
        menuToClose.classList.remove('active')
        const allOtherMenus = Array.from(document.getElementsByClassName('last_depth_level'))

        allOtherMenus.forEach(subMenu => {
          subMenu.classList.remove('active')
          const subMenuSiblingSpan = subMenu.previousElementSibling.childNodes[1]

          if (subMenuSiblingSpan.classList.contains('mobile-filter-chevron')) {
            subMenuSiblingSpan.setAttribute('data-icon', 'n')
          }
        })
      }
    })
  })
}

export default function () {
  attachClickHandlerToBurgerMenuButton()
  slideToggleMobileNavItems()
}
