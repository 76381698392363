import { pushToAlgoliaAnalytics } from '../../analytics/Algolia'
import { listingRevenueData } from '../../analytics/AnalyticsHelpers'
import { getQueryIndex, getQueryID } from '../../utils/search/helpers'

function hasTrackedOrder (orderId) {
  if (window.localStorage) {
    return window.localStorage.getItem(`trackedOrder-${orderId}`) === 'true'
  }
}

function setTrackedOrder (orderId) {
  if (window.localStorage) {
    window.localStorage.setItem(`trackedOrder-${orderId}`, 'true')
  }
}

export default async function () {
  const thankyouPage = document.getElementById('vjs-purchase-complete')
  const lineItemsElement = document.querySelector('[data-id="content-line-items"]')
  const orderIdElement = document.querySelector('[data-id="content-order-id"]')
  if (!thankyouPage || !lineItemsElement || !orderIdElement) return

  // prevent submitting this event multiple times if the user reloads the page
  const orderId = JSON.parse(orderIdElement.innerHTML)
  if (hasTrackedOrder(orderId)) return
  setTrackedOrder(orderId)

  const lineItems = JSON.parse(lineItemsElement.innerHTML)

  const processLineItem = async (lineItem) => {
    const data = listingRevenueData(lineItem)
    const queryID = await getQueryID(lineItem.id)
    const indexUsed = await getQueryIndex(lineItem.id)

    const commonAlgoliaAnalyticsProperties = {
      index: indexUsed || 'Listing_production',
      objectIDs: [lineItem.id.toString()],
      objectData: [data],
      value: data.price,
      currency: 'GBP'
    }

    if (queryID && queryID !== 'undefined') {
      const algoliaAnalyticsProperties = {
        eventName: 'Purchase',
        queryID: queryID,
        ...commonAlgoliaAnalyticsProperties
      }
      pushToAlgoliaAnalytics('purchasedObjectIDsAfterSearch', algoliaAnalyticsProperties)
    } else {
      const algoliaAnalyticsProperties = {
        eventName: 'Purchase - no qID',
        ...commonAlgoliaAnalyticsProperties
      }
      pushToAlgoliaAnalytics('purchasedObjectIDs', algoliaAnalyticsProperties)
    }
  }

  for (const lineItem of lineItems) {
    await processLineItem(lineItem)
  }
}
