import initOmetria from '../libs/ometria/ometria'
import {
  bindEventsByClass,
  bindEventsById
} from '../utils/eventBinding'
import {
  trackHomepageClick,
  trackHomepageHeroClick,
  trackOpenSearchFiltersMobile,
  trackCloseSearchFiltersMobile,
  trackMoreFromSellerClick,
  trackSearchResultsSorted,
  trackNavbarClick,
  trackSellClick,
  trackPDPWishlistButtonClick,
  trackListingTileWishlistButtonClick
} from '../analytics/events/browsing'
import { trackListingImpressions } from '../analytics/events/listingImpressions'
import {
  trackMakeAnOfferIntent,
  trackRequestTradePriceIntent,
  trackRequestQuoteIntent,
  trackEnquirySent
} from '../analytics/events/querying'
import {
  trackTradeProgramIntent,
  trackOauthButtonClick
} from '../analytics/events/user'
import {
  trackOfferInfoClick,
  trackAcceptOffer,
  trackCounterOfferClick,
  trackRejectOfferClick,
  trackHoldListing,
  trackUnholdListing,
  trackEditListing,
  trackSellerEnquiryOfferClick,
  trackMarkAsSold
} from '../analytics/events/seller'
import { getWebCoreVitals } from '../analytics/CoreWebVitals'
import {
  setQueryID,
  setQueryIndex,
  setSearchOrigin
} from '../utils/search/helpers'
import { trackSearchProductClickEvent } from '../analytics/DataLayerEvents'
import { trackAddToCart } from '../analytics/events/addingToCart'
import { trackMakeEnquiry } from '../analytics/events/trackMakeEnquiry'
import { trackCartAction } from '../analytics/events/cart'
import {
  trackCustomClick,
  trackCustomComponentImpression,
  trackCustomSelectDropdown,
  trackCustomClickInAmplitude,
  trackAmplitudeEvent,
  trackAmplitudeExperimentExposure
} from '../analytics/CustomEvents'
import {
  trackListingTitleHighlighted,
  trackListingTitleCopied,
  trackSellerNameHighlighted,
  trackSellerNameCopied
} from '../analytics/trackHighlightCopyEvents'
import {
  trackSaveSearchClick,
  trackSaveSearch
} from '../analytics/events/saveSearch'
import { onVisible } from './utils'

function bindBrowsingEvents () {
  bindEventsByClass('vjs-homepage-hero-click', 'click', e => {
    const { targetUrl, ctaPosition } = e.currentTarget.dataset
    trackHomepageHeroClick(targetUrl, ctaPosition)
  })

  bindEventsByClass('vjs-homepage-click', 'click', e => trackHomepageClick(e.currentTarget.dataset.clickedElement, e.currentTarget.pathname))

  bindEventsByClass('vjs-mobile-filters-open', 'click', trackOpenSearchFiltersMobile)
  bindEventsByClass('vjs-mobile-filters-close', 'click', trackCloseSearchFiltersMobile)

  bindEventsByClass('vjs-more-from-seller', 'click', trackMoreFromSellerClick)
  bindEventsByClass('vjs-sp-sell-click-event', 'click', e => trackSellClick(e.currentTarget.dataset.source))

  bindEventsByClass('vjs-pdp-add-to-wishlist-button', 'click', trackPDPWishlistButtonClick)

  // in order to track across devices and browsers we're using 3 different events to track this
  bindEventsByClass('vjs-seller-name', 'contextmenu', trackSellerNameHighlighted)
  bindEventsByClass('vjs-seller-name', 'mouseup', trackSellerNameHighlighted)
  bindEventsByClass('vjs-seller-name', 'copy', trackSellerNameCopied)

  bindEventsByClass('vjs-listing-title', 'contextmenu', trackListingTitleHighlighted)
  bindEventsByClass('vjs-listing-title', 'mouseup', trackListingTitleHighlighted)
  bindEventsByClass('vjs-listing-title', 'copy', trackListingTitleCopied)

  // This is needed to store the algolia query id in local storage which is
  // otherwise lost if the user opens the listing in another tab. Mousedown is
  // specifically used to precede the click handler defined next.
  bindEventsByClass('vjs-hits-container', 'mousedown', e => {
    const target = e.target.closest('.vjs-results-product-click')
    if (!target) return

    setQueryID(target.dataset.id, target.dataset.queryid)

    const hitsContainer = document.getElementById('hits')
    if (!hitsContainer) return
    const indexUsed = hitsContainer.getAttribute('data-index-used')
    if (!indexUsed) return
    setQueryIndex(target.dataset.id, indexUsed)
  })

  bindEventsByClass('vjs-listings-source', 'mousedown', e => {
    const target = e.target.closest('.vjs-results-product-click')
    if (!target) return
    const sectionOrigin = e.target.closest('.vjs-listings-source')
    if (!sectionOrigin) return
    setSearchOrigin(target.dataset.id, sectionOrigin.dataset.listingsSource)
  })

  // TODO: Merge this `bindEventsByClass('vjs-hits-container', 'mousedown' ` block with the one above (we don't need two listeners)
  bindEventsByClass('vjs-hits-container', 'mousedown', e => {
    if (e.which === 3) return // preclude right clicks
    trackClickOnListingTileChildEl(e)
  })

  bindEventsByClass('vjs-hits-container', 'contextmenu', e => {
    if (e.target.closest('.vjs-results-product-click')) {
      trackSearchProductClickEvent('product_click_right', e.target.closest('.vjs-results-product-click').dataset)
    }
  })

  bindEventsByClass('vjs-sort-by', 'change', e => {
    if (!e.target.closest('.ais-SortBy-select')) return
    trackSearchResultsSorted(e.target.value)
  })

  bindEventsByClass('vjs-navigation', 'click', e => {
    if (!e.target.closest('.vjs-navigation-link')) return
    trackNavbarClick()
  })
}

function bindSellerEvents () {
  bindEventsByClass('vjs-mark-as-sold', 'click', e => trackMarkAsSold(e.currentTarget.dataset.source))
}

function trackNavElOnInteraction (e, trackingFn) {
  const { target } = e
  const { nodeName, classList } = target
  const isPrimaryNavLinkOrSubMenuHeader = classList.contains('vjs-nav-primary-link-tracking') || classList.contains('vjs-nav-sub-menu-header-tracking')

  if (isPrimaryNavLinkOrSubMenuHeader) {
    trackingFn(target.textContent, target.parentElement.href)
  } else if (target.closest('.vjs-navigation-link')) {
    const href = nodeName === 'A' ? target.href : target.parentElement.href
    trackingFn(target.textContent, href)
  }

  if (classList.contains('vjs-mobile-menu-tab')) {
    trackingFn(target.textContent, null)
  }

  if (nodeName === 'SPAN' && target.parentElement.classList.contains('vjs-mobile-menu-tab')) {
    trackingFn(target.parentElement.textContent, null)
  }
}

function bindMainNavEvents () {
  bindEventsById('vjs-toggle-button', 'click', () => {
    trackNavBarCustomClick('Menu', null)
  })

  bindEventsByClass('vjs-nav-primary-link-tracking', 'mouseover', e => {
    trackSubMenuImpressionAfterOpeningDelay(e.target)
  })

  bindEventsByClass('vjs-navigation', 'click', e => {
    trackNavElOnInteraction(e, trackNavBarCustomClick)
  })
}

function trackSubMenuImpressionAfterOpeningDelay (target) {
  setTimeout(() => {
    const { parentElement, textContent, href } = target
    const siblingMenu = parentElement.querySelector('.vjs-collection-menu-visibility-check')
    if (!siblingMenu || siblingMenu.nodeName !== 'DIV') return
    const menuIsVisible = window.getComputedStyle && window.getComputedStyle(siblingMenu).visibility

    if (menuIsVisible === 'visible') trackNavBarCustomImpression(textContent, href)
  }, 300) // menu animates to open in 200ms, so we're allowing a little leeway.
}

function extractUrlPath (href) {
  if (!href) return null
  const url = new URL(href)
  return url.pathname
}

function cleanNavigationTitle (title) {
  return title.split('\n').join('').trim()
}

function trackNavBarCustomClick (category, targetUrl) {
  if (!category) return
  trackCustomClick({
    dataset: {
      customClickName: 'MainNav',
      title: cleanNavigationTitle(category),
      target_url: extractUrlPath(targetUrl)
    }
  })

  trackAmplitudeEvent('Nav Click', {
    title: cleanNavigationTitle(category),
    target_url: extractUrlPath(targetUrl)
  })
}

function trackNavBarCustomImpression (category, targetUrl) {
  if (!category) return
  trackCustomComponentImpression({
    dataset: {
      customComponentName: 'MainNav',
      title: cleanNavigationTitle(category),
      target_url: extractUrlPath(targetUrl)
    }
  })

  trackAmplitudeEvent('Nav Impression', {
    title: cleanNavigationTitle(category),
    target_url: extractUrlPath(targetUrl)
  })
}

export function trackPDPVideoSlideImpression () {
  // has to be called from the video component as only ListingCarousel.jsx knows when a the slide changes
  trackCustomComponentImpression({
    dataset: {
      customComponentName: 'PDP Video',
      amplitudeComponentName: 'PDP Video'
    }
  })
}

function trackClickOnListingTileChildEl (e) {
  const { target } = e

  if (target.closest('.vjs-listing-tile-add-to-wishlist-button')) {
    const { dataset } = target.closest('.vjs-results-product-click')

    trackSearchProductClickEvent('add_to_wishlist_click', dataset)
    trackListingTileWishlistButtonClick(dataset.id)
  } else if (target.closest('.vsd-listing__similar-tag')) {
    // track clicks on the similar tag
    trackSearchProductClickEvent('product_click_view_similar', target.closest('.vjs-results-product-click').dataset)
  } else if (target.closest('.vjs-results-product-click')) {
    trackClicksOnWholeListingTile(e)
  }
}

function trackClicksOnWholeListingTile (e) {
  const { target, which, metaKey } = e

  const mouseEvents = ['', 'product_click', 'product_click_middle', 'product_click_right']
  const clickType = `${mouseEvents[which]}${metaKey ? '_meta' : ''}`
  trackSearchProductClickEvent(clickType, target.closest('.vjs-results-product-click').dataset)
}

function bindQueryingEvents () {
  bindEventsByClass('vjs-make-an-offer-intent', 'click', trackMakeAnOfferIntent)
  bindEventsByClass('vjs-trade-price-intent', 'click', trackRequestTradePriceIntent)
  bindEventsByClass('vjs-quote-intent', 'click', e => trackRequestQuoteIntent(e.currentTarget.dataset.listingId))
  bindEventsByClass('vjs-enquiry-form', 'submit', e => {
    const formData = new FormData(e.currentTarget)
    const enquiryType = formData.get('conversation_enquiry[initial_type]')

    trackEnquirySent(enquiryType)
  })
}

function bindBuyerEnquiryEvents () {
  bindEventsByClass('vjs-make-enquiry', 'click', e => trackMakeEnquiry(e.currentTarget.dataset.listingId))
  bindEventsByClass('vjs-offer-info-modal-icon', 'click', e => trackOfferInfoClick(e.currentTarget.dataset.source))
  bindEventsByClass('vjs-accept-cta', 'click', e => trackAcceptOffer(e.currentTarget.dataset.source))
  bindEventsByClass('vjs-counter-offer-cta', 'click', e => trackCounterOfferClick(e.currentTarget.dataset.source))
  bindEventsByClass('vjs-reject-cta', 'click', e => trackRejectOfferClick(e.currentTarget.dataset.source))
  bindEventsByClass('vjs-hold-listing', 'click', e => trackHoldListing(e.currentTarget.dataset.source))
  bindEventsByClass('vjs-unhold-listing', 'click', e => trackUnholdListing(e.currentTarget.dataset.source))
}

function bindSellerEnquiryEvents () {
  bindEventsByClass('vjs-edit-listing', 'click', e => trackEditListing(e.currentTarget.dataset.source))
  bindEventsByClass('vjs-seller-enquiry-offer', 'click', e => trackSellerEnquiryOfferClick(e.currentTarget.dataset.source))
}

function bindCartEvents () {
  bindEventsByClass('vjs-add-to-cart', 'click', e => {
    trackAddToCart(e.currentTarget.dataset, 'listingPageCTA')
  })

  bindEventsById('vjs-apple-pay-button', 'click', e => {
    trackAddToCart(e.currentTarget.dataset, 'applePayCTA')
  })

  bindEventsByClass('vjs-remove-from-cart', 'click', e => {
    trackCartAction('removeFromCart', e.currentTarget.dataset, 'removeLinkCTA')
  })

  bindEventsByClass('vjs-save-for-later', 'click', e => {
    trackCartAction('addToWishlist', e.currentTarget.dataset, 'saveForLaterCTA')
  })
}

function bindUserEvents () {
  bindEventsByClass('vjs-trade-program-cta', 'click', trackTradeProgramIntent)
  bindEventsByClass('vjs-oauth-button', 'click', e => trackOauthButtonClick(e.currentTarget.dataset.source))
}

function bindCustomClickEvents () {
  bindEventsByClass('vjs-sp-custom-click', 'click', e => {
    trackCustomClick(e.currentTarget)
  })
}

export function bindCustomClickEventsForAmplitude () {
  bindEventsByClass('vjs-amplitude-custom-click', 'click', e => {
    trackCustomClickInAmplitude(e.currentTarget)
  })
}

function bindCustomSelectEvents () {
  bindEventsByClass('vjs-sp-custom-select', 'click', e => {
    trackCustomSelectDropdown(e.currentTarget)
  })
}

function bindSaveSearchEvents () {
  bindEventsById('vjs-save-search-button', 'click', e => {
    trackSaveSearchClick(e.currentTarget)
  })

  bindEventsById('vjs-save-search', 'click', e => {
    trackSaveSearch(e.currentTarget)
  })
}

function bindCustomComponentImpressionEvents () {
  const elements = Array.from(document.getElementsByClassName('vjs-custom-component-impression'))
  elements.forEach(el => onVisible(el, trackCustomComponentImpression))
}

function bindAmplitudeExperimentExposureEvents () {
  const elements = Array.from(document.getElementsByClassName('vjs-amplitude-experiment-exposure'))
  elements.forEach(el => {
    const { exposureTrigger } = el.dataset
    if (exposureTrigger === 'on-visible') {
      onVisible(el, trackAmplitudeExperimentExposure)
    } else {
      trackAmplitudeExperimentExposureAfterAmplitudeLoad(el, 0)
    }
  })
}

// we need to wait until after Amplitude has loaded to fire the exposure event
function trackAmplitudeExperimentExposureAfterAmplitudeLoad (el, count) {
  if (count > 2) return
  if (window.amplitude) {
    trackAmplitudeExperimentExposure(el)
  } else {
    setTimeout(() => trackAmplitudeExperimentExposureAfterAmplitudeLoad(el, count + 1), 0)
  }
}

export default function initAnalytics () {
  window.bindCustomComponentImpressionEvents = bindCustomComponentImpressionEvents
  window.trackListingImpressions = trackListingImpressions

  initOmetria()

  bindBrowsingEvents()
  bindSellerEvents()
  bindMainNavEvents()
  bindQueryingEvents()
  bindBuyerEnquiryEvents()
  bindSellerEnquiryEvents()
  bindCartEvents()
  bindUserEvents()
  bindCustomClickEvents()
  bindCustomSelectEvents()
  bindCustomComponentImpressionEvents()
  bindSaveSearchEvents()
  bindCustomClickEventsForAmplitude()
  bindAmplitudeExperimentExposureEvents()

  getWebCoreVitals()
}
