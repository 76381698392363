import { pushToDataLayer } from './PushToDataLayer'
import { camelCaseToSnakeCase } from '../utils/stringHelpers'

export function trackCustomSelectDropdown (selectElement) {
  const selectedValue = selectElement.options[selectElement.selectedIndex].text
  const dataset = { ...selectElement.dataset, selectedOption: selectedValue }

  trackCustomEvent(dataset, 'customClick', 'customClickName')
}

export function trackCustomClick (element) {
  const dataset = element.dataset
  trackCustomEvent(dataset, 'customClick', 'customClickName')
}

export function trackCustomClickInAmplitude (element) {
  const { amplitudeComponentName, amplitudeEventName, ...rest } = element.dataset
  if (!amplitudeComponentName && !amplitudeEventName) return

  const amplitudeEventProperties = Object
    .keys(rest)
    .filter(key => isAmplitudeDataVariable(key))
    .map(key => ({ [formatAmplitudeEventPropertyKey(key)]: rest[key] }))

  const dataLayerAttributes = {
    // amplitudeEventName is defined when we are not emitting a "[Component name] Clicked", mostly for older events as "Clicked" is the preferred format.
    amplitude_event_name: amplitudeEventName || getAmplitudeClickEventName(amplitudeComponentName),
    amplitude_event_properties: Object.assign({}, ...amplitudeEventProperties)
  }

  // TODO (Joj): change this to 'amplitudeCustomEvent (Vinterior)' in the next PR - changing in GTM at the same time.
  pushToDataLayer('amplitudeCustomClickTracking (Vinterior)', dataLayerAttributes)
}

function getAmplitudeClickEventName (amplitudeComponentName) {
  // Temporary solution as Amplitude is already receiveing data with this specific event name. Just waiting for Viktoria
  // to sign off changing it to 'Log In/Register Clicked', then we can remove this.
  if (amplitudeComponentName === 'Log In/Register Click') return amplitudeComponentName

  return amplitudeComponentName + ' Clicked'
}

// TODO: Update the above click tracking and this one in GTM to be "generic" and not "click"
// Make use of the generic amplitude_event_name and amplitude_event_properties in the Data Layer
export function trackAmplitudeEvent (eventName, eventProperties) {
  const dataLayerAttributes = {
    amplitude_event_name: eventName,
    amplitude_event_properties: eventProperties
  }

  // TODO (Joj): change this to 'amplitudeCustomEvent (Vinterior)' in the next PR - changing in GTM at the same time.
  pushToDataLayer('amplitudeCustomClickTracking (Vinterior)', dataLayerAttributes)
}

export function trackAmplitudeExperimentExposure (element) {
  const { amplitudeExperimentFlagKey, amplitudeExperimentVariant } = element.dataset

  if (amplitudeExperimentFlagKey && amplitudeExperimentVariant) {
    const dataLayerAttributes = {
      amplitude_event_properties: {
        flag_key: amplitudeExperimentFlagKey,
        variant: amplitudeExperimentVariant
      }
    }

    pushToDataLayer('amplitudeExperimentExposure (Vinterior)', dataLayerAttributes)
  }
}

export function trackCustomComponentImpression (element) {
  const dataset = element.dataset
  trackCustomEvent(dataset, 'customComponentImpression', 'customComponentName')

  const { amplitudeComponentName } = dataset
  if (amplitudeComponentName) trackAmplitudeEvent(amplitudeComponentName + ' Viewed')
}

function trackCustomEvent (dataset, eventName, snowplowPropertyName) {
  const dataLayerAttributes = { custom_properties: createCustomProperties(dataset, snowplowPropertyName) }

  dataLayerAttributes[camelCaseToSnakeCase(snowplowPropertyName)] = dataset[snowplowPropertyName]

  pushToDataLayer(`${eventName} (Vinterior)`, dataLayerAttributes)
}

function createCustomProperties (dataset, snowplowPropertyName) {
  // returns array like [{name: 'listing_id', value: 1}, {name: 'enquiry', value: 'general'}]
  const customPropertiesObject = Object.assign({}, dataset)
  delete customPropertiesObject[snowplowPropertyName]
  return Object.keys(customPropertiesObject).map(key => { return { name: camelCaseToSnakeCase(key), value: customPropertiesObject[key] } })
}

function isAmplitudeDataVariable (key) {
  // Temporary solution so we don't add data-amplitude-component-names to sent amplitude event properties
  // TODO Joj: Will find a more elegant solution for this
  if (key === 'amplitudeComponentName') return
  return key.startsWith('amplitude')
}

function formatAmplitudeEventPropertyKey (key) {
  return camelCaseToSnakeCase(key).slice(10)
}
