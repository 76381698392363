export function getCookie (name) {
  const nameString = `${name}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookieItem = cookieArray[i]
    while (cookieItem.charAt(0) === ' ') {
      cookieItem = cookieItem.substring(1)
    }
    if (cookieItem.indexOf(nameString) === 0) {
      return cookieItem.substring(nameString.length, cookieItem.length)
    }
  }
  return ''
}

export function setCookie (name, value, daysToExpiry) {
  const date = new Date()
  const epochDate = date.getTime()
  date.setTime(epochDate + (daysToExpiry * 24 * 60 * 60 * 1000))
  const expiry = `expires=${date.toUTCString()}`
  document.cookie = `${name}=${value};${expiry};path=/`
}
