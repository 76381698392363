export default function setAccordions () {
  // TODO: (Joj) this is a bit overcomplicated and brittle. Too many things change on expand and
  // we use a combination of vsd classes and inline styles. We could simplify with Tailwind classes.
  // Also it should be able to start opened, as well as closed.
  const accordions = Array.from(document.querySelectorAll('.vjs-accordion'))

  accordions.forEach(accordion => {
    const button = accordion.querySelector('.vjs-accordion-button')
    if (!button) return

    button.addEventListener('click', () => {
      const collapsible = accordion.querySelector('.vjs-accordion-collapsible')

      button.classList.toggle('vsd-accordion__button--active')

      if (collapsible.classList.contains('vjs-accordion__collapsible--expanded')) {
        accordion.dataset.amplitudeClickType = 'Collapse'
        collapsible.classList.remove('vjs-accordion__collapsible--expanded', 'max-h-full', 'overflow-visible')
      } else {
        accordion.dataset.amplitudeClickType = 'Expand'
        collapsible.classList.add('vjs-accordion__collapsible--expanded', 'max-h-full', 'overflow-visible')
      }
    })
  })
}
