import { pushToAlgoliaAnalytics } from '../Algolia'
import {
  getQueryID,
  getQueryIndex
} from '../../utils/search/helpers'

// Starting to see some of this can be DRYed, will do later
export async function trackMakeEnquiry (listingId) {
  const queryID = await getQueryID(listingId)
  const indexUsed = await getQueryIndex(listingId)

  const commonAlgoliaAnalyticsProperties = {
    index: indexUsed || 'Listing_production',
    objectIDs: [listingId]
  }

  if (queryID && queryID !== 'undefined') {
    const algoliaAnalyticsProperties = {
      eventName: 'Make an Enquiry',
      queryID: queryID,
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('convertedObjectIDsAfterSearch', algoliaAnalyticsProperties)
  } else {
    const algoliaAnalyticsProperties = {
      eventName: 'Make an Enquiry - no qID',
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('convertedObjectIDs', algoliaAnalyticsProperties)
  }
}
