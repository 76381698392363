export default function initNavBarMenus () {
  const navBarMenus = document.querySelectorAll('.vjs-nav-bar-menu')
  const navBarMenuContents = document.querySelectorAll('.vjs-nav-bar-menu-content')

  navBarMenus.forEach(menu => {
    menu.addEventListener('mouseover', () => {
      const menuId = menu.id
      const menuContent = Array.from(navBarMenuContents).find(content => content.dataset.menuContent === menuId)

      navBarMenus.forEach(menu => menu.classList.remove('vsd-active'))
      navBarMenuContents.forEach(content => content.classList.remove('vsd-visible'))

      menu.classList.add('vsd-active')
      menuContent.classList.add('vsd-visible')
    })
  })

  attachClickHandlerToBurgerMenuButton()
  slideToggleMobileNavItems()
}

function attachClickHandlerToBurgerMenuButton () {
  const burgerBtn = document.getElementById('vjs-toggle-button')
  const burgerMenu = document.getElementById('vjs-mobile-menu')
  if (!burgerBtn || !burgerMenu) return

  burgerBtn.addEventListener('click', function () {
    window.scrollTo({ top: 0 })

    if (burgerMenu.classList.contains('hidden')) {
      burgerMenu.classList.remove('hidden')

      if (!burgerMenu.classList.contains('vjs-mobile-admin')) {
        document.body.classList.add('noscroll')
      }
    } else {
      burgerMenu.classList.add('hidden')
      document.body.classList.remove('noscroll')
    }
  })
}

function slideToggleMobileNavItems () {
  setUpMobileMenusToggleOnClick()
  setUpMobileLastDeptMenusDisplay()
  setUpMobileMenusBackButton()
}

function slideToggle (targetElement) {
  if (!targetElement) return

  const isMenuHidden = targetElement.classList.contains('hidden')

  if (isMenuHidden) {
    showMenuScreen(targetElement)
  } else {
    hideMenuScreen(targetElement)
  }
}

function showChevronUp (element) {
  const elementChevronUp = Array.from(element.getElementsByClassName('vjs-filter-up-chevron'))[0]
  const elementChevronDown = Array.from(element.getElementsByClassName('vjs-filter-down-chevron'))[0]

  if (elementChevronUp) elementChevronUp.classList.add('hidden')
  if (elementChevronDown) elementChevronDown.classList.remove('hidden')
}

function showChevronDown (element) {
  const elementChevronUp = Array.from(element.getElementsByClassName('vjs-filter-up-chevron'))[0]
  const elementChevronDown = Array.from(element.getElementsByClassName('vjs-filter-down-chevron'))[0]

  if (elementChevronUp) elementChevronUp.classList.remove('hidden')
  if (elementChevronDown) elementChevronDown.classList.add('hidden')
}

function setUpMobileMenusToggleOnClick () {
  const triggersMobileMenu = document.getElementsByClassName('vjs-mobile-menu-tab')

  Array.from(triggersMobileMenu).forEach(function (element) {
    const targetMobileMenuElement = document.querySelector(element.dataset.targetElement)

    element.addEventListener('click', function () {
      if (targetMobileMenuElement) {
        targetMobileMenuElement.classList.toggle('hidden')
        toggleChevron(element, targetMobileMenuElement)
      }
    })
  })
}

function setUpMobileLastDeptMenusDisplay () {
  const lastDepthLevelMenus = document.getElementsByClassName('vjs-last-depth-level-menu')

  Array.from(lastDepthLevelMenus).forEach(function (element) {
    const lastDepthLevelMenu = document.querySelector(element.dataset.targetElement)

    element.addEventListener('click', function () {
      slideToggle(lastDepthLevelMenu)
    })
  })
}

function setUpMobileMenusBackButton () {
  const mobileMenuBackLinks = Array.from(document.getElementsByClassName('vjs-menu-back-link'))

  mobileMenuBackLinks.forEach(element => {
    element.addEventListener('click', function () {
      const menuToClose = element.parentNode.parentNode
      const isMenuVisible = !menuToClose.classList.contains('hidden')

      if (isMenuVisible) {
        menuToClose.classList.add('hidden')
        const allOtherMenus = Array.from(document.getElementsByClassName('vjs-last-depth-level-menu'))

        allOtherMenus.forEach(subMenu => {
          subMenu.classList.add('hidden')
        })
      }
    })
  })
}

function toggleChevron (element, targetMobileMenuElement) {
  const doesNotHaveChevron = !element.childNodes[1].classList.contains('vjs-mobile-filter-chevron')

  if (targetMobileMenuElement.classList.contains('hidden') && doesNotHaveChevron) {
    showChevronUp(element)
  } else if (doesNotHaveChevron) {
    showChevronDown(element)
  }
}

function showMenuScreen (element) {
  element.classList.remove('hidden')
  element.classList.add('h-0')

  const height = element.clientHeight + 'px'

  setTimeout(function () {
    element.classList.add('h-[' + height + ']')
  }, 0)
}

function hideMenuScreen (element) {
  element.classList.add('h-0')
  element.addEventListener('transitionend', function () {
    element.classList.add('hidden')
  }, {
    once: true
  })
}
