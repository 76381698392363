/* global Event */

export default function initCustomSelects (container = document) {
  const selects = Array.from(container.querySelectorAll('.vjs-custom-select__container select'))
  selects.forEach(mapSelectToProxy)
}

function mapSelectToProxy (select) {
  const selectOptions = Array.from(select.getElementsByTagName('option'))
  const proxySelect = hideSelectAndGetProxy(select)
  const proxyMenu = getProxyDropDown(proxySelect)
  const proxyOptions = getProxyLisFromOptions(selectOptions, select, proxySelect)

  proxyOptions.forEach((proxyOption, index) => {
    proxyMenu.appendChild(proxyOption)
    proxyOption.addEventListener('click', () => handleMenuItemClick(selectOptions[index], select, proxySelect))
  })

  // Update proxy when changing value on underlying select
  select.addEventListener('click', () => {
    handleMenuItemClick(selectOptions[select.selectedIndex], select, proxySelect)
  })

  // start proxy innerText on already selected input option or first
  if (select.selectedIndex) {
    proxySelect.innerText = selectOptions[select.selectedIndex].innerText
  } else {
    proxySelect.innerText = selectOptions[0].innerText
  }
  setUpMenuOpenAndClose(proxySelect, select)
}

function hideSelectAndGetProxy (select) {
  select.classList.add('vsd-native-select--hidden')

  const proxySelect = document.createElement('div')
  proxySelect.classList.add('vsd-proxy-select__display', 'vjs-proxy-select__display')

  proxySelect.tabIndex = '0'
  select.insertAdjacentElement('afterend', proxySelect)

  return proxySelect
}

function getProxyDropDown (proxySelect) {
  const proxyMenu = document.createElement('ul')
  proxySelect.insertAdjacentElement('afterend', proxyMenu)
  proxyMenu.classList.add('vsd-proxy-select__menu', 'vjs-proxy-select__menu')

  return proxyMenu
}

function getProxyLisFromOptions (selectOptions) {
  return selectOptions.map(selectOption => {
    const optionText = selectOption.innerText
    const proxyLi = document.createElement('li')

    proxyLi.innerText = optionText
    Object.assign(proxyLi.dataset, selectOption.dataset)

    if (optionText === 'Please select' || selectOption.value === '') {
      proxyLi.classList.add('hidden')
    }

    return proxyLi
  })
}

function handleMenuItemClick (selectOption, select, proxySelect) {
  select.selectedIndex = selectOption.index
  proxySelect.innerText = selectOption.innerText
  selectOption.click()
}

function setUpMenuOpenAndClose (proxySelect, select) {
  const selectContainer = proxySelect.parentElement
  proxySelect.addEventListener('click', e => {
    e.stopPropagation()
    if (e.target.parentElement?.matches('.vsd-field--disabled')) return

    const clickedMenuIsAlreadyOpen = selectContainer.classList.contains('vsd-custom-select__container--open')

    if (clickedMenuIsAlreadyOpen) {
      selectContainer.classList.remove('vsd-custom-select__container--open')
    } else {
      closeAllSelectMenus()
      selectContainer.classList.add('vsd-custom-select__container--open')
    }

    handleClicksOutsideMenu(select)
  })
}

function handleClicksOutsideMenu (select) {
  document.addEventListener('click', e => {
    e.stopPropagation()
    if (e.target.parentElement?.matches('.vjs-proxy-select__menu')) return
    closeAllSelectMenus()
    dispatchChangeEvent(select)
  }, { once: true })
}

function closeAllSelectMenus () {
  const selectMenus = Array.from(document.querySelectorAll('.vjs-custom-select__container'))
  selectMenus.forEach(selectMenu => selectMenu.classList.remove('vsd-custom-select__container--open'))
}

function dispatchChangeEvent (select) {
  const changeEvent = new Event('change', {
    view: window,
    bubbles: true
  })

  select.dispatchEvent(changeEvent)
}
