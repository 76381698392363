import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DefaultSlider from './DefaultSlider'
import Lightbox from 'react-image-lightbox'

import { trackPDPVideoSlideImpression } from '../../src/analytics'
class ListingCarousel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSlide: 0,
      isOpen: false
    }
  }

  getSlide (index) {
    if (this.props.slides[index].is_video) {
      return this.getVideoSlide(index)
    } else {
      return this.getImageSlide(index)
    }
  }

  getImageSlide (index) {
    return (
      <div key={index}>
        <img
          className='slider-image'
          onClick={() => this.setState({ isOpen: true })}
          src={index === 0 ? this.props.main_photo_url : this.props.slides[index].large}
          alt={this.props.slides[index].alt_text}
        />
      </div>
    )
  }

  getVideoSlide (index) {
    return (
      <div className='flex' key={index}>
        <video
          controls='play'
          className='mx-auto'
          id='vjs-carousel-video-slide'
          preload='metadata'
          muted
          alt={this.props.slides[index].alt_text}
        >
          <source src={this.props.slides[index].large} type='video/mp4' />
        </video>
      </div>
    )
  }

  render () {
    const slides = this.props.slides
    const settings = {
      useTransform: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      dots: true,
      arrows: true,
      afterChange: current => {
        this.setState({ activeSlide: current })
        if (this.props.slides[current].is_video) {
          document.getElementById('vjs-carousel-video-slide').play()
          trackPDPVideoSlideImpression()
        }
      },
      customPaging: function (i) {
        return (
          <div
            className='slick-page-center-croped'
            style={{ backgroundImage: `url(${slides[i].thumbnail})` }}
          />
        )
      }
    }

    return (
      <div>
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.props.slides[this.state.activeSlide].original}
            mainSrcThumbnail={this.props.slides[this.state.activeSlide].thumbnail}
            nextSrc={this.props.slides[(this.state.activeSlide + 1) % this.props.slides.length].original}
            nextSrcThumbnail={this.props.slides[(this.state.activeSlide + 1) % this.props.slides.length].thumbnail}
            prevSrc={this.props.slides[(this.state.activeSlide + this.props.slides.length - 1) % this.props.slides.length].original}
            prevSrcThumbnail={this.props.slides[(this.state.activeSlide + this.props.slides.length - 1) % this.props.slides.length].thumbnail}
            imagePadding='0'
            onMovePrevRequest={() =>
              this.setState({
                activeSlide: (this.state.activeSlide + this.props.slides.length - 1) % this.props.slides.length
              })}
            onMoveNextRequest={() =>
              this.setState({
                activeSlide: (this.state.activeSlide + 1) % this.props.slides.length
              })}
            onAfterOpen={() => {
              document.documentElement.classList.add('noscroll')
              document.body.classList.toggle('noscroll', true)
            }}
            onCloseRequest={() => {
              this.setState({ isOpen: false })
              document.documentElement.classList.remove('noscroll')
              document.body.classList.toggle('noscroll', false)
            }}
          />
        )}

        <DefaultSlider {...settings}>
          {this.props.slides.map((src, index) => this.getSlide(index))}
        </DefaultSlider>
      </div>
    )
  }
}

ListingCarousel.propTypes = {
  main_photo_url: PropTypes.string,
  slides: PropTypes.array,
  main_photo_min_height: PropTypes.number,
  showVideo: PropTypes.bool
}

export default ListingCarousel
