import { pushToDataLayer } from '../PushToDataLayer'
import { pushToFacebookCAPI } from '../PushToFacebookCAPI'
import { ometriaAddToCart } from '../../libs/ometria/ometria'
import { pushToAlgoliaAnalytics } from '../Algolia'
import {
  getQueryID,
  getQueryIndex,
  getSearchOrigin
} from '../../utils/search/helpers'
import {
  getActionEventId,
  getRemarketingProperties,
  getAllListingAttributes,
  listingRevenueData
} from '../AnalyticsHelpers'

async function trackSearchConversion (listingAttributes, source) {
  const queryID = await getQueryID(listingAttributes.id)
  const indexUsed = await getQueryIndex(listingAttributes.id)
  const searchOrigin = await getSearchOrigin(listingAttributes.id)

  /* eslint-disable */
  const searchConversionProperties = {
    search_conversion: {
      search_query_id: queryID,
      listing_id: parseInt(listingAttributes.id),
      click_source: source,
      search_index_used: indexUsed,
      search_origin: searchOrigin
    }
  }
  pushToDataLayer('sp - search conversion', searchConversionProperties)
  /* eslint-enable */

  const data = listingRevenueData(listingAttributes)
  const commonAlgoliaAnalyticsProperties = {
    index: indexUsed || 'Listing_production',
    objectIDs: [listingAttributes.id.toString()],
    objectData: [data],
    value: data.price,
    currency: 'GBP'
  }

  if (queryID && queryID !== 'undefined') {
    const algoliaAnalyticsProperties = {
      eventName: 'Add to cart',
      queryID: queryID,
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('addedToCartObjectIDsAfterSearch', algoliaAnalyticsProperties)
  } else {
    const algoliaAnalyticsProperties = {
      eventName: 'Add to cart - no qID',
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('addedToCartObjectIDs', algoliaAnalyticsProperties)
  }
}

export function trackAddToCart (initialListingAttributes, source) {
  const actionEventId = getActionEventId()
  const listingAttributes = getAllListingAttributes(initialListingAttributes)

  const dataLayerProperties = {
    /* eslint-disable */
    listingEngagedWith: listingAttributes,
    clickSource: source,
    fb_action_event_id: actionEventId,
    ...getRemarketingProperties([listingAttributes])
    /* eslint-disable */
  }

  pushToDataLayer('addToCart (Vinterior)', dataLayerProperties)
  pushToFacebookCAPI('AddToCart', listingAttributes.id, actionEventId)
  ometriaAddToCart(listingAttributes.id)
  trackSearchConversion(listingAttributes, source)
}
