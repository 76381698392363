import {
  getRefinementsListAsKeyValuePairs,
  getRefinementListForSPSchema,
  getSPListingImpressions
} from './AnalyticsHelpers'
import {
  trackSearchResultsViewed,
  trackSearchResultsFiltered,
  trackSearchResultsClicked
} from './events/browsing'
import {
  getImpressions,
  simplifyImpression
} from './utils/impressions'

export function trackSearchProductClickEvent (clickType, productObj) {
  const listingId = parseInt(productObj.id)

  // Impressions are set by setUpSearchProductsEvent
  const impressions = getImpressions()
  const clickedListing = impressions.filter(function (impression) {
    return impression.listing_id === listingId
  }).pop() // From the end because:
  // We're storing impressions on subsequent filter interactions, so last is most recent query

  trackSearchResultsClicked(
    clickType,
    productObj.queryid,
    parseInt(productObj.position),
    listingId,
    clickedListing
  )
}

// Tracks single refinement interaction.
// Could be infered from the filter_state entity on search results events but this
// might make it easier for analysis
export function setUpRefineSearchEvents (formattedParameters, queryID) {
  const refinementsList = getRefinementsListAsKeyValuePairs(formattedParameters)
  let firstRun = false
  if (window.activeFilters === undefined) {
    window.activeFilters = []
    firstRun = true
  }

  refinementsList.forEach((keyValuePair) => {
    keyValuePair.refinementValues.forEach((filterValue) => {
      const currentFilter = {
        filterName: keyValuePair.refinementKey,
        filterValue: filterValue
      }

      const isFilterActive = window.activeFilters.some(function (activeFilter) {
        const sameName = activeFilter.filterName === currentFilter.filterName
        const sameValue = activeFilter.filterValue === currentFilter.filterValue

        return sameName && sameValue
      })

      if (isFilterActive) return
      window.activeFilters.push(currentFilter)

      // Active filters during the page load are not tracked
      if (firstRun) return

      trackSearchResultsFiltered(
        queryID,
        keyValuePair.refinementKey,
        filterValue,
        formattedParameters
      )
    })
  })
}

// Only tracks analytics, it's not responsible for pushing impressions into data layer
export function setUpSearchProductsEvent (path, results, state, formattedParameters) {
  const { hits, queryID } = results
  const { query, page } = state

  const appliedFilters = getRefinementListForSPSchema(formattedParameters)
  const snowplowImpressions = getSPListingImpressions(hits, query, page)

  // Extract subset of listing data for snowplow event schema
  const simplifiedImpressions = snowplowImpressions.map(simplifyImpression)

  trackSearchResultsViewed(
    queryID,
    query,
    appliedFilters,
    simplifiedImpressions,
    path
  )
}
