import { pushToDataLayer } from '../PushToDataLayer'
import { getAllListingAttributes } from '../AnalyticsHelpers'

export function trackCartAction (actionName, initialListingAttributes, source) {
  const listingAttributes = getAllListingAttributes(initialListingAttributes)

  const dataLayerProperties = {
    listingEngagedWith: listingAttributes,
    clickSource: source
  }

  pushToDataLayer(`${actionName} (Vinterior)`, dataLayerProperties)
}
