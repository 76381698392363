import validateCheckableInput from '../validation_utils/validateCheckableInputs'

export default function reviewFormValidation () {
  const reviewForm = document.getElementById('vjs-review-form')

  if (reviewForm) {
    reviewForm.addEventListener('submit', event => {
      validateCheckableInput(event, 'vjs-order-received')
      validateCheckableInput(event, 'vjs-review-rating')
    })
  }
}
