import React from 'react'
import PropTypes from 'prop-types'
import closeButton from '../../images/icons/close-black.svg'

class Reviews extends React.Component {
  // Adapted from V2 Modals
  showModal (id) {
    const modal = document.querySelector('#vjs-review-listing-modal-' + id)
    if (modal) {
      modal.classList.add('show')
    }

    modal.addEventListener('click', event => {
      if (event.target === modal) {
        modal.classList.remove('show')
      }
    })

    const closeButtons = modal.querySelectorAll('.vjs-modal-close')
    closeButtons.forEach(closeButton => {
      closeButton.addEventListener('click', () => {
        modal.classList.remove('show')
      })
    })
  }

  render () {
    return (
      <div>
        <ul>
          {this.props.reviewsToRender.map((review, index) => (
            <div className='vsd-listing__review' key={index}>
              <div className='mb-8'>
                <span className='vsd-listing__review-buyer-name'> {review.buyer_name} </span>
                <span className='vsd-listing__review-date'> {review.updated_at_readable} </span>
              </div>

              <div className='vsd-listing__seller-rating' style={{ '--rating': review.rating }} aria-label={'Rating of this product is' + review.rating + 'out of 5.'}> </div>

              <div className='vsd-listing__review-comment'> {review.comment} </div>

              <div className='mb-16'>
                <span className='vsd-listing__review-verified'>Verified purchase:</span>
                <span onClick={() => this.showModal(review.id)} className='vsd-listing__review-listing-title cursor-pointer'>{review.listing_title}</span>
              </div>

              <div>
                <img onClick={() => this.showModal(review.id)} alt='Listing image' className='vsd-listing__review-listing-image cursor-pointer' src={review.listing_image_url} />
              </div>

              <div id={'vjs-review-listing-modal-' + review.id} className='vsd-modal vjs-modal'>
                <div className='vsd-modal__dialog size-lg'>
                  <button type='button' className='vsd-modal__close vjs-modal-close' aria-label='Close'>
                    <img src={closeButton} />
                  </button>
                  <div className='vsd-modal__header'>
                    <h3>Verified Purchase</h3>
                  </div>
                  <div className='vsd-modal__content'>
                    <img alt='Listing image' style={{ maxWidth: '100%' }} src={review.listing_image_url} />
                    <div className='vsd-listing__review-listing-title'> {review.listing_title} </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
    )
  }
}

Reviews.propTypes = {
  reviewsToRender: PropTypes.array
}

export default Reviews
