import { pushToAlgoliaAnalytics } from '../Algolia'
import { isBot } from '../../utils/search/helpers'

export function trackListingImpressions (impressions) {
  if (isBot()) return
  const objectIds = impressions.map(item => item.listing_id.toString())
  if (!objectIds || objectIds.length === 0) return

  const maxChunkSize = 10
  for (let i = 0; i < objectIds.length; i += maxChunkSize) {
    const chunk = objectIds.slice(i, i + maxChunkSize)
    const algoliaAnalyticsProperties = {
      eventName: 'PLP Impression',
      index: 'Listing_production',
      objectIDs: chunk
    }

    pushToAlgoliaAnalytics('viewedObjectIDs', algoliaAnalyticsProperties)
  }
}
