import { pushToDataLayer } from '../PushToDataLayer'

// NOTE: All seller events are structured under ecommerce.click
function ecommerceClickAttributes (attributes) {
  return {
    ecommerce: {
      click: attributes
    }
  }
}

export function trackCreateListing (source) {
  pushToDataLayer('createListingManual (Vinterior)', ecommerceClickAttributes({
    create_listing_manual_source: source
  }))
}

export function trackEditListing (source) {
  pushToDataLayer('editListing (Vinterior)', ecommerceClickAttributes({
    edit_listing_source: source
  }))
}

export function trackSaveListing (source) {
  pushToDataLayer('saveListing (Vinterior)', ecommerceClickAttributes({
    save_listing_source: source
  }))
}

export function trackRelistListing (source) {
  pushToDataLayer('relistListing (Vinterior)', ecommerceClickAttributes({
    relist_listing_source: source
  }))
}

export function trackMarkAsSold (source) {
  pushToDataLayer('markedAsSold (Vinterior)', ecommerceClickAttributes({
    mark_as_sold_source: source
  }))
}

export function trackUnmarkAsSold (source) {
  pushToDataLayer('unmarkedAsSold (Vinterior)', ecommerceClickAttributes({
    unmark_as_sold_source: source
  }))
}

export function trackHoldListing (source) {
  pushToDataLayer('holdListing (Vinterior)', ecommerceClickAttributes({
    hold_listing_source: source
  }))
}

export function trackUnholdListing (source) {
  pushToDataLayer('unholdListing (Vinterior)', ecommerceClickAttributes({
    unhold_listing_source: source
  }))
}

export function trackUnmarkAsStale (source) {
  pushToDataLayer('unmarkedAsStaleListing (Vinterior)', ecommerceClickAttributes({
    unmark_as_stale_source: source
  }))
}

export function trackSellerListingsSearch () {
  pushToDataLayer('sellerListingsSearch (Vinterior)')
}

export function trackSellerListingsPagination () {
  pushToDataLayer('sellerListingsPagination (Vinterior)')
}

export function trackImportUrls (source) {
  pushToDataLayer('importUrls (Vinterior)', ecommerceClickAttributes({
    import_urls_source: source
  }))
}

export function trackSubmitUrls (source) {
  pushToDataLayer('submitUrls (Vinterior)', ecommerceClickAttributes({
    submit_urls_source: source
  }))
}

export function trackAcceptOffer (listingId) {
  pushToDataLayer('acceptOffer (Vinterior)', ecommerceClickAttributes({
    clicked_element: listingId
  }))
}

export function trackCounterOfferClick (listingId) {
  pushToDataLayer('counterOfferClick (Vinterior)', ecommerceClickAttributes({
    clicked_element: listingId
  }))
}

export function trackRejectOfferClick (listingId) {
  pushToDataLayer('rejectOfferClick (Vinterior)', ecommerceClickAttributes({
    clicked_element: listingId
  }))
}

export function trackSellClick (source) {
  pushToDataLayer('Sell Link Clicked', ecommerceClickAttributes({
    clicked_element: source
  }))
}

export function trackOfferInfoClick (listingId) {
  pushToDataLayer('offerInfoClick (Vinterior)', ecommerceClickAttributes({
    clicked_element: listingId
  }))
}

export function trackSellerEnquiryOfferClick (listingId) {
  pushToDataLayer('sellerEnquiryOfferClick (Vinterior)', ecommerceClickAttributes({
    clicked_element: listingId
  }))
}
