import { scrollToInvalid } from './scrollToInvalid'

export default function validateCheckableInput (submitEvent, parentId) {
  const formParent = document.getElementById(parentId)
  if (!formParent) return

  const checkableChecked = Array.from(formParent.getElementsByTagName('input'))
    .filter(input => input.checked === true).length > 0

  if (!checkableChecked) {
    submitEvent.preventDefault()
    styleRadioAsInvalid(formParent, parentId)
  } else {
    styleRadioAsValid(formParent, parentId)
  }
}

function styleRadioAsValid (formParent, parentId) {
  const errorEl = document.getElementById(`${parentId}-error`)
  const labelEl = document.querySelector(`label[for="${parentId}"]`)
  if (!errorEl) return

  errorEl.textContent = ''

  formParent.classList.remove('vsd-form-field--invalid')
  errorEl.classList.remove('vsd-form-field__error--visible')

  if (labelEl) {
    labelEl.classList.remove('vsd-form-label--invalid')
  }
}

function styleRadioAsInvalid (formParent, parentId) {
  const errorEl = document.getElementById(`${parentId}-error`)
  const labelEl = document.querySelector(`label[for="${parentId}"]`)
  if (!errorEl) return

  errorEl.textContent = 'This field is required'

  formParent.classList.add('vsd-form-field--invalid')
  errorEl.classList.add('vsd-form-field__error--visible')

  if (labelEl) {
    labelEl.classList.add('vsd-form-label--invalid')
  }

  scrollToInvalid(formParent)
}
