export default function initCountDownTimers () {
  const countdownToDateEls = Array.from(document.querySelectorAll('.vjs-countdown-to-date'))
  countdownToDateEls.forEach(countdownToDateEl => initCoundownToDateTimer(countdownToDateEl))
}

function initCoundownToDateTimer (countdownToDateEl) {
  const endDateString = countdownToDateEl.dataset.endDate
  // endDate is in the format of 'YYYY-MM-DD', HH and MM are optional
  if (!endDateString) return

  countdownToDateEl.classList.remove('hidden')
  const [endYear, endMonth, endDay, endHour, endMinute] = endDateString.split('-')
  const endDate = new Date(endYear, endMonth - 1, endDay, endHour || 0, endMinute || 0)

  tickCountdownToDateTimer(countdownToDateEl, endDate)
}

function tickCountdownToDateTimer (countdownToDateEl, endDate) {
  const currentDate = new Date()
  const timeLeft = endDate - currentDate

  if (timeLeft < 0) {
    countdownToDateEl.classList.add('hidden')
    return
  }

  const oneSec = 1000
  const oneMin = oneSec * 60
  const oneHour = oneMin * 60

  const secondsLeft = Math.floor((timeLeft % oneMin) / oneSec)
  const minutesLeft = Math.floor((timeLeft % oneHour) / oneMin)
  const hoursLeft = Math.floor(timeLeft / oneHour)

  const secondsLeftEl = countdownToDateEl.querySelector('.vjs-countdown-seconds')
  const minutesLeftEl = countdownToDateEl.querySelector('.vjs-countdown-minutes')
  const hoursLeftEl = countdownToDateEl.querySelector('.vjs-countdown-hours')

  secondsLeftEl.textContent = secondsLeft
  minutesLeftEl.textContent = minutesLeft
  hoursLeftEl.textContent = hoursLeft

  setTimeout(() => tickCountdownToDateTimer(countdownToDateEl, endDate), 250)
}
