/* global Event */

export default function customCheckboxButtons () {
  const checkboxFormParents = Array.from(document.querySelectorAll('.vjs-custom-checkbox-button-form'))
  checkboxFormParents.forEach(checkboxFormParent => styleCheckboxFormOnClick(checkboxFormParent))
}

function styleCheckboxFormOnClick (checkboxFormParent) {
  const checkboxButtons = Array.from(checkboxFormParent.querySelectorAll('.vjs-custom-checkbox-button'))

  checkboxButtons.forEach(button => button
    .addEventListener('click', e => toggleCheckboxState(button, e)))
}

function toggleCheckboxState (button, e) {
  const changeEvent = new Event('change', { bubbles: true })
  const checkbox = button.querySelector('input[type=checkbox]')
  const inputIsChecked = checkbox.checked

  if (e.target.tagName !== 'INPUT') {
    toggleInputChecked(button, inputIsChecked)
    checkbox.dispatchEvent(changeEvent)
  }
  toggleButtonStyle(button, inputIsChecked)
}

function toggleInputChecked (button, inputIsChecked) {
  inputIsChecked ? unCheckSelected(button) : checkSelected(button)
}

function toggleButtonStyle (button, inputIsChecked) {
  inputIsChecked ? styleDeselected(button) : styleSelected(button)
}

function checkSelected (selected) {
  const selectedCheckbox = selected.querySelector('input[type=checkbox]')
  if (selectedCheckbox) selectedCheckbox.checked = true
}

function unCheckSelected (selected) {
  const selectedCheckbox = selected.querySelector('input[type=checkbox]')
  if (selectedCheckbox) selectedCheckbox.checked = false
}

function styleSelected (button) {
  button.style.borderWidth = '1.6px'
}

function styleDeselected (button) {
  button.style.borderWidth = '1px'
}
