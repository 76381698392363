// React components used on Design V2
import ReactRailsUJS from 'react_ujs'

export default () => {
  const componentRequireContext = require.context(
    '../',
    true,

    // modules under v2_components
    /^\.\/(v2_components)\/.*$/
  )

  // ReactRailsUJS will automatically mount components in major document events
  ReactRailsUJS.useContext(componentRequireContext)
}
