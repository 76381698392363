import { pushToDataLayer } from '../PushToDataLayer'

export function trackMakeAnOfferIntent () {
  pushToDataLayer('buyerMakeAnOfferClick (Vinterior)')
}

export function trackRequestQuoteIntent (listingId) {
  pushToDataLayer('request quote click', {
    ecommerce: {
      click: {
        clicked_element: `${listingId}`
      }
    }
  })
}

export function trackRequestTradePriceIntent () {
  pushToDataLayer('requestTradePrice (Vinterior)')
}

export function trackEnquirySent (enquiryType) {
  pushToDataLayer('enquiry sent', {
    enquiryType: enquiryType
  })
}
