function addEventListenerToHTMLCollection (trackedElements, action, pushEvent) {
  if (!trackedElements) return

  Array.from(trackedElements).forEach(trackedElement => {
    trackedElement.addEventListener(action, pushEvent)
  })
}

export function bindEventsByClass (className, action, pushEvent) {
  // not wrapping these in Arrays as they looks like we indentionally wanted HTMLCollections
  const trackedElements = document.getElementsByClassName(className)
  addEventListenerToHTMLCollection(trackedElements, action, pushEvent)
}

export function bindEventsByTagName (tagName, action, pushEvent) {
  // not wrapping these in Arrays as they looks like we indentionally wanted HTMLCollections
  const trackedElements = document.getElementsByTagName(tagName)
  addEventListenerToHTMLCollection(trackedElements, action, pushEvent)
}

export function bindEventsById (id, action, pushEvent) {
  const trackedElement = document.getElementById(id)
  if (!trackedElement) return
  trackedElement.addEventListener(action, pushEvent)
}
