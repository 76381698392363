import { trackAmplitudeEvent } from '../analytics/CustomEvents'

export default function initLandingSurveyModal () {
  const modal = document.getElementById('vjs-landing-survey-modal')
  const otherReasonHereRadioBtn = document.getElementById('vjs-landing-survey-reason5')
  const otherReasonBuyingRadioBtn = document.getElementById('vjs-landing-survey-buying-reason6')
  if (!modal || !otherReasonHereRadioBtn || !otherReasonBuyingRadioBtn) return // modal won't be on page if user already answered
  otherReasonHereRadioBtn.addEventListener('change', () => {
    const textBox = document.getElementById('vjs-landing-survey-other-answer')
    if (textBox) textBox.classList.remove('hidden')
  })

  otherReasonBuyingRadioBtn.addEventListener('change', () => {
    const textBox = document.getElementById('vjs-landing-survey-buying-reason-other-answer')
    if (textBox) textBox.classList.remove('hidden')
  })

  if (!window.sessionStorage.getItem('landingSurveyShown')) {
    modal.classList.add('show')
    window.sessionStorage.setItem('landingSurveyShown', 'true')
    // Think there's an issue with racing condition when trying to use the Amplitude custom impression
    // tracking we have with "modals" that load on page render
    // as noticed the numbers were way off with the VIP Modal
    // so doing this instead
    trackAmplitudeEvent('Landing Survey Modal Viewed')
  }
}
