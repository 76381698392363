import { removeEventListenersByClass } from '../utils/eventListenerRemoving'
import { trackTextHighlightedOrCopied } from './events/browsing'

export function trackSellerNameHighlighted () {
  trackTextHighlightedOrCopied('seller name', 'highlight')
  removeEventListenersByClass('vjs-seller-name', 'mouseup', trackSellerNameHighlighted)
  removeEventListenersByClass('vjs-seller-name', 'contextmenu', trackSellerNameHighlighted)
}

export function trackSellerNameCopied () {
  trackTextHighlightedOrCopied('seller name', 'copy')
  removeEventListenersByClass('vjs-seller-name', 'copy', trackSellerNameCopied)
}

export function trackListingTitleCopied () {
  trackTextHighlightedOrCopied('listing title', 'copy')
  removeEventListenersByClass('vjs-listing-title', 'copy', trackListingTitleCopied)
}

export function trackListingTitleHighlighted () {
  trackTextHighlightedOrCopied('listing title', 'highlight')
  removeEventListenersByClass('vjs-listing-title', 'mouseup', trackListingTitleHighlighted)
  removeEventListenersByClass('vjs-listing-title', 'contextmenu', trackListingTitleHighlighted)
}
