export function slideToggle (targetElement) {
  if (!targetElement) return
  if (!targetElement.classList.contains('active')) {
    targetElement.classList.add('active')
    targetElement.style.height = 'auto'
    const height = targetElement.clientHeight + 'px'
    targetElement.style.height = '0px'
    setTimeout(function () {
      targetElement.style.height = height
    }, 0)
  } else {
    targetElement.style.height = '0px'
    targetElement.addEventListener('transitionend', function () {
      targetElement.classList.remove('active')
    }, {
      once: true
    })
  }
}

export function showCollection (elements, specifiedDisplay) {
  let element, index

  elements = elements.length ? elements : [elements]
  for (index = 0; index < elements.length; index++) {
    element = elements[index]

    // Remove the element's inline display styling
    element.style.display = ''
    const computedDisplay = window.getComputedStyle(element, null).getPropertyValue('display')
    const isDisabled = element.disabled === true

    if (computedDisplay === 'none' || isDisabled) {
      // we need to disable and undisable because Safari won't hide select options
      // https://github.com/Vinterior/vinterior-app/pull/5162
      element.disabled = false
      element.style.display = specifiedDisplay || 'block'
    }
  }
}

export function hideCollection (elements) {
  elements = elements.length ? elements : [elements]
  for (let index = 0; index < elements.length; index++) {
    elements[index].disabled = true
    elements[index].style.display = 'none'
  }
}
