import validateTextInput from '../validation_utils/validateTextInput'
import validateSelect from '../validation_utils/validateSelect'

export default function lineItemConfirmationFormValidation () {
  const confirmationForm = document.getElementById('vjs-confirmation-form')

  if (confirmationForm) {
    confirmationForm.addEventListener('submit', event => {
      validateTextInput(event, 'vjs-line-item-delivery-notes', setConfirmationAttributeErrorText)
      const shipmentType = document.querySelector('input[name="line_item[shipment_type]"]:checked')
      const isVatRegistered = document.querySelector('input[name="seller_account[vat_registered]"]:checked')

      if (!shipmentType) return

      validateSelect(event, 'vjs-delivery_time_estimate-select', setConfirmationAttributeErrorText)
      if (shipmentType.value === 'vinterior_delivery') validationFormElement(event, 'input[name="proovia_terms"]:checked', '#vjs-partnership-terms-container')
      if (shipmentType.value === 'self_delivery') validationFormElement(event, '#vjs-own-delivery-price', '#vjs-own-delivery-badges')
      if (isVatRegistered && isVatRegistered.value === 'true') validateVatInvoiceForm(event)
      if (shipmentType.value === 'eu_vinterior_delivery') {
        validateTextInput(event, 'vjs-listing-weight', setConfirmationAttributeErrorText)
        validateSelect(event, 'vjs-listing-material', setConfirmationAttributeErrorText)
        validateSelect(event, 'vjs-listing-period', setConfirmationAttributeErrorText)
      }
    })
  }
}

function validateVatInvoiceForm (event) {
  const textInputs = [
    'vjs-business-tax-id',
    'vjs-registered-business-name',
    'vjs-registered-business-number'
  ]

  textInputs.forEach(inputId => {
    validateTextInput(event, inputId, setConfirmationAttributeErrorText)
  })
}

function setConfirmationAttributeErrorText (validityObject, errorEl) {
  const { valueMissing } = validityObject

  if (valueMissing) errorEl.textContent = 'This field is required'
}

function validationFormElement (event, validationSelector, errorParentSelectorId) {
  const validityCheck = document.querySelector(validationSelector)
  const errorEl = document.querySelector(`${errorParentSelectorId} ~ .vsd-form-field__error`)
  if (!errorEl) return

  if (validityCheck && validityCheck.value) {
    errorEl.textContent = ''
    errorEl.classList.remove('vsd-form-field__error--visible')
  } else {
    event.preventDefault()
    errorEl.classList.add('vsd-form-field__error--visible')
    errorEl.textContent = 'This field is required'
  }
}
