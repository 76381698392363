import { trackCustomClick } from '../analytics/CustomEvents'

export default function setTruncatedSections () {
  const truncatabaleContent = Array.from(document.querySelectorAll('.vjs-truncated-content'))
  if (!truncatabaleContent) return

  truncatabaleContent.forEach(truncatableContent => {
    const isVerticallyTruncated = checkIfVerticallyTruncated(truncatableContent)
    const isHorizontallyTruncated = checkIfHorizontallyTruncated(truncatableContent)

    if (isVerticallyTruncated || isHorizontallyTruncated) setUpTruncatableSection(truncatableContent)
  })
}

function setUpTruncatableSection (truncatableContent) {
  const showMore = truncatableContent.parentNode.querySelector('.vjs-truncated-show-more')
  const showLess = truncatableContent.parentNode.querySelector('.vjs-truncated-show-less')

  resetTruncatedSectionState(showMore, showLess, truncatableContent)

  showMore.classList.remove('hidden')

  const clonedShowMore = replaceAndReturnCloneOf(showMore)
  const clonedShowLess = replaceAndReturnCloneOf(showLess)

  showMoreOnClick(clonedShowMore, clonedShowLess, truncatableContent)
  showLessOnClick(clonedShowLess, clonedShowMore, truncatableContent)
}

function showMoreOnClick (clonedShowMore, clonedShowLess, truncatableContent) {
  clonedShowMore.addEventListener('click', e => {
    truncatableContent.classList.remove('vsd-truncated__description-collapsed')
    revealIfHorizontallyTruncated(truncatableContent)
    clonedShowMore.classList.toggle('hidden')
    clonedShowLess && clonedShowLess.classList.toggle('hidden')
    trackCustomClick(e.currentTarget)
  })
}

function showLessOnClick (clonedShowLess, clonedShowMore, truncatableContent) {
  clonedShowLess.addEventListener('click', e => {
    truncatableContent.classList.add('vsd-truncated__description-collapsed')
    clonedShowLess.classList.toggle('hidden')
    clonedShowMore && clonedShowMore.classList.toggle('hidden')
    hideIfHorizontallyTruncating(truncatableContent)
    trackCustomClick(e.currentTarget)
  })
}

function revealIfHorizontallyTruncated (truncatableContent) {
  const truncatedTextInline = truncatableContent.querySelector('.vjs-truncated-text-inline')
  if (truncatedTextInline) truncatedTextInline.classList.remove('truncate')
}

function hideIfHorizontallyTruncating (truncatableContent) {
  const truncatedTextInline = truncatableContent.querySelector('.vjs-truncated-text-inline')
  if (truncatedTextInline) truncatedTextInline.classList.add('truncate')
}

function replaceAndReturnCloneOf (node) {
  // reset nodes and remove their click listeners by cloning them
  const clone = node.cloneNode(true)
  node.parentNode.replaceChild(clone, node)
  return clone
}

function resetTruncatedSectionState (showMore, showLess, truncatableContent) {
  // reset state so we can navigate in PLPs and keep calling setTruncatedSections
  truncatableContent.classList.add('vsd-truncated__description-collapsed')
  showMore.classList.add('hidden')
  showLess.classList.add('hidden')
}

function checkIfVerticallyTruncated (truncatableContent) {
  return truncatableContent.scrollHeight !== truncatableContent.clientHeight
}

function checkIfHorizontallyTruncated (truncatableContent) {
  const truncatedTextInline = truncatableContent.querySelector('.vjs-truncated-text-inline')
  if (!truncatedTextInline) return false
  return truncatedTextInline.offsetWidth < truncatedTextInline.scrollWidth
}
