export function removeEventListenersByClass (className, action, listener) {
  // not wrapping these in Arrays as they looks like we indentionally wanted HTMLCollections
  const trackedElements = document.getElementsByClassName(className)
  removeEventListenerFromHTMLCollection(trackedElements, action, listener)
}

function removeEventListenerFromHTMLCollection (trackedElements, action, listener) {
  if (!trackedElements) return

  Array.from(trackedElements).forEach(trackedElement => {
    trackedElement.removeEventListener(action, listener)
  })
}
